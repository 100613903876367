import React from "react";

import UserService from "../../../services/UserService";
import { logo, DownArrow, Notif, Avatar } from "./icons";
import "./style.css";

function Header() {
  return (
    <header>
      <div className="container py-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="logo-part">
            <div className="logo">
              <a href="/" className="d-flex justify-content-between align-items-center">
                <img src={logo} alt="brand-logo" />
                <span className="logo-label league-font">PLAYGROUND</span>
              </a>
            </div>
          </div>
          <div className="menus-part">
            <div className="menus">
              <div className="header-actions">
                <div className="actionBtns">
                  <div className="notif">
                    <img src={Notif} alt="" />
                  </div>
                </div>
                <div className="avtar">
                  <div className="profile-btn">
                    <img className="profile-img" src={Avatar} alt="" />
                    <img className="down-arrow" src={DownArrow} alt="" />
                  </div>
                  <div className="PrfileMoreMenu">
                    <ul>
                      <li>
                        <a href="#">Profile</a>
                      </li>
                      <li>
                        <a href="#">Setting</a>
                      </li>

                      {!UserService.isLoggedIn() && (
                        <li onClick={() => UserService.doLogin()}>
                          <a href="#">Login</a>
                        </li>
                      )}

                      {UserService.isLoggedIn() && (
                        <li onClick={() => UserService.doLogout()}>
                          <a href="#">Logout </a>
                        </li>
                        // {UserService.getUsername()}
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export { Header };
