import React from "react";
import { useNavigate } from "react-router-dom";

import { Layout } from "../../components";

import "./styles/style.css";

function Errorpage() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <div className="container error-page d-flex flex-column justify-content-center align-items-center">
        <div className="">
          <h1 className="league-font display-5 text-center">ERROR, THIS PAGE {`DOESN'T`} EXIST</h1>
          <button
            className="btn go-back-btn col-6 offset-3 text-white league-font my-3"
            onClick={goBack}
          >
            Go Back{" "}
            <svg height="20" width="20" viewBox="0 0 448 512" fill="#ffffff">
              <path d="M25.4 278.6L2.7 256l22.6-22.6 144-144L192 66.7 237.2 112l-22.6 22.6L125.2 224 416 224l32 0 0 64-32 0-290.7 0 89.4 89.4L237.2 400 192 445.3l-22.6-22.6-144-144z" />
            </svg>
          </button>
        </div>
      </div>
    </Layout>
  );
}

export { Errorpage };
