import api from "../../utils/api";
import { AUTH_ERROR, SET_FIX_STATUS_EXPLOIT, GET_ATTEMPTS_HISTORY } from "./types";

export const setFixStatusExploit =
  ({ key, id }) =>
  async (dispatch) => {
    try {
      const res = await api.get(`/fixes/vulnerability/${key}/session/${id}/exploit`);
      dispatch({
        type: SET_FIX_STATUS_EXPLOIT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

export const getSessionAttemptsHistory =
  ({ id, title }) =>
  async (dispatch) => {
    try {
      const res = await api.get(`/fixes/session/${id}/vulnerability/${title}/attempts/history`);
      dispatch({
        type: GET_ATTEMPTS_HISTORY,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };
