import {
  AUTH_ERROR,
  CHECK_RESPONSE,
  GET_ALL_CHALLENGES,
  GET_CHALLENGES_BY_KEY,
  GET_CHALLENGES_FIXES,
  GET_CHALLENGES_VULNERABILITIES,
  GET_FIX_BY_ID_CONTENT,
  GET_VULNERABILITIES_CODE,
  SET_FILTER_LIST,
  SET_ADDITIONAL_CHALLENGES,
  LOGOUT,
  SET_EMPTY_ADDITIONAL_CHALLENGES,
  GET_ALL_LANGUAGE,
  GET_MY_CHALLANGES,
} from "../actions/types";

const initialState = {
  challenges: [],
  challengesByKey: [],
  vulnerabilities: [],
  vulnerabilityCode: {
    code: null,
  },
  challengesFixes: [],
  fixByIdContent: {
    code: null,
  },
  fixResponse: {},
  filterList: {
    technologies: [],
    languages: [],
    levels: [],
  },
  additionalChallenges: [],
  languages: [],
  totalPages: 1,
  pageable: {},
};

const challengesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_CHALLENGES:
      return {
        ...state,
        challenges: payload,
      };
    case GET_ALL_LANGUAGE:
      return{
        ...state,
        languages: payload,
      }
    case GET_CHALLENGES_FIXES:
      return {
        ...state,
        challengesFixes: payload,
      };
    case GET_CHALLENGES_BY_KEY:
      return {
        ...state,
        challengesByKey: payload,
      };
    case GET_CHALLENGES_VULNERABILITIES:
      return {
        ...state,
        vulnerabilities: payload,
      };
    case GET_VULNERABILITIES_CODE:
      return {
        ...state,
        vulnerabilityCode: { code: payload },
      };
    case GET_FIX_BY_ID_CONTENT:
      return {
        ...state,
        fixByIdContent: { code: payload },
      };
    case CHECK_RESPONSE:
      return {
        ...state,
        fixResponse: payload,
      };
    case SET_FILTER_LIST:
      return {
        ...state,
        challenges: payload.content,
        totalPages: payload.totalPages,
        pageable: payload.pageable,
        // filterList: {
        //   technologies: payload.technologies,
        //   languages: payload.languages,
        //   levels: payload.levels,
        // },
      };
    case SET_ADDITIONAL_CHALLENGES:
      return {
        ...state,
        challenges: payload,
      };
    case SET_EMPTY_ADDITIONAL_CHALLENGES:
      return {
        ...state,
        additionalChallenges: [],
      };
    case GET_MY_CHALLANGES:
      return{
        ...state,
        challenges: payload.content,
      }
    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        ...payload,
      };

    default:
      return state;
  }
};

export default challengesReducer;
