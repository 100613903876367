import React, { useState } from "react";
import "./style.css"
function SmAct({ label, dropDownItems, callback }) {
  const [showDropDown, setShowDropDown] = useState(false);
  return (
    <div className="dropdown-container">
      <div className="dropdown mx-2 dropdown-menu-container" >
        <span
          aria-expanded="false"
          className="dropdown-toggle px-4 py-2"
          onClick={()=>{
            setShowDropDown(!showDropDown);
          }}
        >
          {label} <span className="badge text-bg-light ms-3 me-1">{dropDownItems && dropDownItems.length}</span>
        </span>
       {
         showDropDown && dropDownItems && dropDownItems.map((item, index)=>{
           return <span className="drop-down-items" key={index} onClick={()=>{setShowDropDown(false); callback(item);}}>{item}</span>
         })
       }
      </div>
    </div>
  );
}

export { SmAct };
