import api from "../../utils/api";
import {
  AUTH_ERROR,
  CHECK_SESSION_STATUS,
  GET_ALL_SESSION,
  GET_SESSION_ID,
  GET_SESSION_STATUS,
  GET_SESSION_VULNERABILITIES,
  SET_SESSION_STATUS,
  START_SESSION,
} from "./types";

export const getSession = () => async (dispatch) => {
  try {
    const res = await api.get("/session/0/20/date");
    dispatch({
      type: GET_ALL_SESSION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getSessionVulnerabilities = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/session/${id}/vulnerabilities`);
    dispatch({
      type: GET_SESSION_VULNERABILITIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const setSessionStatus = (payload) => (dispatch) => {
  try {
    dispatch({
      type: SET_SESSION_STATUS,
      payload,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getSessionStatus = () => (dispatch) => {
  try {
    dispatch({
      type: GET_SESSION_STATUS,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const startSession = (payload) => async (dispatch) => {
  try {
    const res = await api.post(`/session/challenge/${payload}/start`);
    dispatch({
      type: START_SESSION,
      payload: res,
    });
  } catch (error) {
    console.log(error.toJSON());
    // dispatch({
    //   type: AUTH_ERROR,
    // });
  }
};

export const getSessionId = (payload) => async (dispatch) => {
  try {
    const res = await api.get(`/session/challenge/${payload}/0/50/date`);
    dispatch({
      type: GET_SESSION_ID,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const checkSessionStatus = (payload) => async (dispatch) => {
  try {
    const res = await api.get(`/session/${payload}/check`);
    dispatch({
      type: CHECK_SESSION_STATUS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
