import React from "react";
import "./styles/style.css";

function Footer() {
  return (
    <footer className="footer pt-5 pb-3">
      <p className="copyrightNew league-font text-uppercase">
        @2023 Cybercops ... All rights reserved.
      </p>
    </footer>
  );
}

export { Footer };
