import api from "../../utils/api";
import {
  AUTH_ERROR,
  CHECK_RESPONSE,
  GET_ALL_CHALLENGES,
  GET_ALL_LANGUAGE,
  GET_CHALLENGES_BY_KEY,
  GET_CHALLENGES_FIXES,
  GET_CHALLENGES_VULNERABILITIES,
  GET_FIX_BY_ID_CONTENT,
  GET_VULNERABILITIES_CODE,
  SET_ADDITIONAL_CHALLENGES,
  SET_EMPTY_ADDITIONAL_CHALLENGES,
  SET_FILTER_LIST,
} from "./types";

export const getChallenges = () => async (dispatch) => {
  try {
    const res = await api.get("/session/challenges");
    dispatch({
      type: GET_ALL_CHALLENGES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getLanguage = () => async (dispatch) => {
  try {
    const res = await api.get("/challenges/languages");
    dispatch({
      type: GET_ALL_LANGUAGE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getChallengesByKey = (key) => async (dispatch) => {
  try {
    const res = await api.get(`/challenges/getChallengeByKey/${key}`);
    dispatch({
      type: GET_CHALLENGES_BY_KEY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getChallengesVulnerabilities = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/challenges/getVulnerabilities/${id}`);
    dispatch({
      type: GET_CHALLENGES_VULNERABILITIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getVulnerabilityCode =
  ({ key, title }) =>
  async (dispatch) => {
    try {
      const res = await api.get(`/challenges/getVulnerabilityCode/${key}/${title}`);
      dispatch({
        type: GET_VULNERABILITIES_CODE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

export const getChallengesFixes =
  ({ title, key }) =>
  async (dispatch) => {
    try {
      const res = await api.get(`/challenges/getFixes/${key}/${title}`);
      dispatch({
        type: GET_CHALLENGES_FIXES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

export const getFixByIdContent =
  ({ title, key, fixId }) =>
  async (dispatch) => {
    try {
      const res = await api.get(`/challenges/getFixByIdContent/${key}/${title}/${fixId}`);
      dispatch({
        type: GET_FIX_BY_ID_CONTENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

export const checkResponse =
  ({ key, title, id, fixId }) =>
  async (dispatch) => {
    try {
      const res = await api.get(
        `/fixes/${fixId}/challenge/${key}/vulnerability/${title}/session/${id}/valid`
      );
      dispatch({
        type: CHECK_RESPONSE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

export const filterOptions = (payload) => async (dispatch) => {
  try {
    const { language, status, level, page } = payload;
    const url = `/challenges?page=${page-1}&size=12&sort=title,asc${language ? `&language=`+language :``}${status ? `&status=`+status:`&status=all`}${level ? `&level=`+level : ``}`; 
    const res = await api.get(url);
    dispatch({
      type: SET_FILTER_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const setAdditionalChallenges = (payload) => async (dispatch) => {
  console.log(payload, "payload");
  const { filterBy, filterName, page, size } = payload;
  try {
    const res = await api.get(`/challenges/${filterBy}/${filterName}/${page}/${size}/filter`);
    dispatch({
      type: SET_ADDITIONAL_CHALLENGES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const setEmptyAdditionalChallenges = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_EMPTY_ADDITIONAL_CHALLENGES,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getMyChallanges = () => async (dispatch) => {
  try {
    const res = await api.get(`challenges?page=0&size=10&sort=title,asc&status=started,done,affected`)
    dispatch({
      type: GET_ALL_CHALLENGES,
      payload: res.data.content,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
