import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ArrowDown, IssueIcon, Tick, ProTip, FilterIcon, LabelIcon } from "../icons";

import { getSessionVulnerabilities } from "../../../redux/actions";
import { CongratulationMessage, IssueLists, Layout, SubHeader } from "../../../components";

import store from "../../../redux/store";
import "./styles/style.css";

function IssuePage() {
  let { title } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vulnerabilities } = useSelector((state) => state?.session);
  const [vulnerabilitiesState, setVulnerabilitiesState] = useState([]);
  const [key] = useState(localStorage.getItem("key"));

  useEffect(() => {
    if (!key) {
      navigate("/", true);
    }
  }, []);

  useEffect(() => {
    try {
      dispatch(getSessionVulnerabilities(title)).then(() => {
        const data = store.getState().session.vulnerabilities;
        setVulnerabilitiesState(data);
      });
    } catch (error) {
      console.log("error", error);
    }
  }, [dispatch]);

  const handleSearch = ({ target: { value } }) => {
    if (value.toLowerCase() === "") {
      setVulnerabilitiesState(vulnerabilities);
    } else {
      const searchResult = vulnerabilities.filter((item) =>
        item.title.toLowerCase().includes(value)
      );
      setVulnerabilitiesState(searchResult);
    }
  };

  return (
    <Layout>
      <div className="issuePage">
        <div className="container">
          <SubHeader title={key} />
          <div className="filterWrps mt-5">
            <div className="searchBox">
              <input type="text" onChange={handleSearch} placeholder="Search is.issues  is.open" />
              <span>
                <img src={FilterIcon} alt="" />
                <img src={ArrowDown} className="arrow" alt="" />
              </span>
            </div>
            <div className="lbl_mltn ms-4">
              <button>
                <img src={LabelIcon} alt="" /> Labels
              </button>
            </div>
          </div>
          <div className="bottomWrps mt-5">
            <div className="allIssues">
              <div className="allIssues_heading">
                <div className="allIssues_heading-left">
                  <b>
                    <img src={IssueIcon} alt="" /> {vulnerabilities.length} Open
                  </b>
                  <span>
                    <b>
                      <img src={Tick} alt="" /> 7,150 issued closed
                    </b>
                  </span>
                </div>
                <div className="allIssues_heading-right">
                  <ul>
                    <li>
                      <a href="#">
                        Author <img src={ArrowDown} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Label <img src={ArrowDown} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        sort <img src={ArrowDown} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Showing issue lists with pagination */}
              <IssueLists data={vulnerabilitiesState ? vulnerabilitiesState : vulnerabilities} />

              {/* Showing Congratulation while no more issue */}
              {IssueLists.length < 0 ? <CongratulationMessage /> : ""}
            </div>
            <h5 className="proTip league-font">
              <img src={ProTip} alt="" /> <span>ProTip!</span> Adding <span>no:label</span> will
              show everything without a label.
            </h5>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export { IssuePage };
