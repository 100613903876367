import React from "react";
import "./style.css";

function CongratulationMessage() {
  return (
    <div className="congratulation">
      <h3>Congratulation </h3>
    </div>
  );
}

export { CongratulationMessage };
