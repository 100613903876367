import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../common/loader";

import { flagIcon, KnifIcon, pullRequestIcon, rocketIcon, userGroupIcon } from "../icons";

function IssueLists({ data }) {
  const { title } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("key")) {
      navigate("/", true);
    }
  }, []);

  const handleIssues = (item) => {
    navigate(`/issuesDescription/${title}/${item.key}`);
  };

  let PageSize = 15;

  const [currentPage] = useState(1);

  const paginatedData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage]);

  return (
    <div className="vulnerability-container">
      {data.length > 0 ? (
        <>
          <div className="issues-list-container">
            {paginatedData.length > 0
              ? paginatedData.map((item, i) => (
                  <div
                    role="button"
                    onClick={() => handleIssues(item)}
                    key={i}
                    className="issueBLk"
                  >
                    <div className="issueBLk-left">
                      {flagIcon}
                      {console.log(item)}
                      <div className="issueBLk-left-wrp">
                        <div className="issuetitle">
                          {item.status === "solved" && (
                            <p>
                              <s>{item.key}</s>
                            </p>
                          )}
                          {item.status !== "solved" && <p>{item.title}</p>}
                          <span className="yellow">{item?.metadata?.cwe}</span>
                          {item?.metadata?.owasp?.map((item, i) => (
                            <span className="green" key={i}>
                              {item}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="issueBLk-right">
                      {item.status === "exploited" && (
                        <img className="mx-2" src={KnifIcon} alt="" />
                      )}
                      {item.status === "solved" && <div className="mx-2">{pullRequestIcon}</div>}
                      <div className="mx-2">{userGroupIcon}</div>
                      <div className="mx-2">{rocketIcon}</div>
                    </div>
                  </div>
                ))
              : data.slice(0, PageSize).map((item, i) => (
                  <div
                    role="button"
                    onClick={() => handleIssues(item)}
                    key={i}
                    className="issueBLk"
                  >
                    <div className="issueBLk-left">
                      {flagIcon}
                      <div className="issueBLk-left-wrp">
                        <div className="issuetitle">
                          <p>{item.title} </p>
                          <span className="yellow">{item?.metadata?.cwe}</span>
                          {item?.metadata?.owasp?.map((item, i) => (
                            <span className="green" key={i}>
                              {item}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="issueBLk-right">
                      {item.status === "exploited" && (
                        <img className="mx-2" src={KnifIcon} alt="" />
                      )}
                      {item.status === "solved" && <div className="mx-2">{pullRequestIcon}</div>}
                      <div className="mx-2">{userGroupIcon}</div>
                      <div className="mx-2">{rocketIcon}</div>
                    </div>
                  </div>
                ))}
          </div>
        </>
      ) : (
        <Loader height="10vh" />
      )}
    </div>
  );
}

export { IssueLists };
