import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import Loader from "../../components/common/loader";
import { getChallenges, getSession } from "../../redux/actions";
import { Layout } from "../../components";
import { ChallengeCard } from "../../components/card/challengeCard";
import {
  filterOptions, getLanguage, getMyChallanges,
} from "../../redux/actions/challenges";
import "./styles/style.css";
import { Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { GET_ALL_CHALLENGES } from "../../redux/actions/types";
import { SmAct } from "../issue/utils";
import Pagination from "../../components/pagination/pagination";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    color: '#133b83',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#133B83 !important',
      '& + .MuiSwitch-track': {
        backgroundColor: '#D9D9D9',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#133b83',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function Dashboard() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { challenges } = useSelector((state) => { return state.challenges });
  const languages = useSelector((state) => state.challenges.languages);
  const totalPages = useSelector((state) => state.challenges.totalPages);
  const pageable = useSelector((state)=> state.challenges.pageable);
  const [seeOnlyMyCourses, setSeeOnlyMyCourse] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const status = {
    done: 'RETAKE',
    finished: 'RETAKE',
    undone: 'START LEARNING',
    assigned: "START LEARNING",
    started: "CONTINUE"
  }
  const LogoStatus = {
    done: 'COMPLETED',
    undone: 'NOT COMPLETED',
    affected: "ASSIGNED",
    started: "STARTED"
  }
  const levelLabel = [
    "BEGINNER",
    "MEDIUM",
    "ADVANCED",
  ]

  useEffect(() => {
    try {
      dispatch(getSession()).then(() => {
        setLoading(false);
      });
      dispatch(getLanguage());
      dispatch(handleFilter());
    } catch (error) {
      console.log("error", error);
    }
  }, [dispatch]);

  const handleSearch = ({ target: { value } }) => {
    if (value.toLowerCase() === "") {
      dispatch(getChallenges());
    } else {
      const searchResult = challenges.filter((item) => item.title.toLowerCase().includes(value));
      console.log(searchResult);
      dispatch({
        type: GET_ALL_CHALLENGES,
        payload: searchResult,
      });
    }
  };

  const handleFilter = (item, filter) => {
    const filterData = {language : selectedLanguage, status: selectedStatus, level: selectedLevel, page: pageNumber}
    switch (filter) {
      case 'language':
        filterData.language = item;
        setSelectedLanguage(item);
        break;
      case 'status':
        filterData.status = Object.keys(LogoStatus).filter((status)=>{
          return LogoStatus[status] === item
        })[0];
        setSelectedStatus(filterData.status);
        break;
      case 'level':
        filterData.level = item;
        setSelectedLevel(item);
        break;
      case 'page_number':
        filterData.pageNumber = item;
        setPageNumber(item);
        break;
      default:
        filterData
        break;
    }

    dispatch(
      filterOptions(filterData)
    ).then(()=>{
      console.log('sucess');
      // setSelectedLanguage(item);
    });
  };


  const getChallengeCard = (challenge, key) => {
    return (
      Object.keys(challenge).length > 0 ? (
        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
          <ChallengeCard
            key={key}
            logo={challenge.key}
            devicon={challenge.devicon}
            status={LogoStatus[challenge.challengestatus]}
            title={challenge.title}
            numberOfExercise={challenge.vulnerabilities.length}
            level={challenge.level}
            LevelAction={status[challenge.challengestatus]}
          />
        </Grid>
      ) : <h5>
        <p className="text-warning">No started challenges</p>
      </h5>
    )
  };
  const handleSeeOnlyMyChallanges = () =>{
    try {
      if(seeOnlyMyCourses){
        setSeeOnlyMyCourse(false);
        dispatch(getChallenges());
      }else{
        dispatch(getMyChallanges()).then(()=>{
        setSeeOnlyMyCourse(true);
        },
        ()=>{
          setSeeOnlyMyCourse(false);
        }
        );
      }  
    } catch (error) {
      console.log(error);
    }
    
  }

  
  return (
    <Layout>
      {!loading && challenges ? (
        <div className="dashboard-page pb-5">
          <div className="welcome-banner py-5 my-2">
            <div className="container">
              <h1 className="text-uppercase text-white league-font">Welcome to your study room</h1>
              <p className="text-white">Your home for hands-on software security training</p>
            </div>
          </div>
          <Grid container spacing={2} className="menu-container">
            <Grid item xs={12}>
              <Grid container>
                {/* lg={3} md={4} sm={6} xs={12}  */}
                <Grid item xl={9} lg={9} md={9} sm={6} xs={12} key="assignment">  <h1 className="text-uppercase text-white league-font">MY ASSIGNMENT</h1></Grid>
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12} key="my-courses" style={{display: 'flex', flexDirection: 'row', gap: '1%', justifyContent: 'space-between',alignItems: 'center'}}>
                  <Typography style={{
                    fontFamily: 'Consolas',
                    fontStyle: 'normal',
                  }}> See Only My Courses</Typography>
                  <FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} checked={seeOnlyMyCourses} onClick={()=>{ handleSeeOnlyMyChallanges() }}/>}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container rowSpacing={2}>
                <Grid item  xl={9} lg={9} md={9} sm={12} xs={12} className="filter-container dashbaord-text-color" key="filter-option">
                    <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                      <SmAct 
                      label={"Status"} 
                      num={"858"} 
                      dropDownItems={
                        Object.keys(LogoStatus).map((status) => {
                          return LogoStatus[status]                          
                        })
                      } 
                      callback={(selectedOption)=>{
                        handleFilter(selectedOption, 'status')
                      }}
                      />
                    </Grid>
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                              <SmAct 
                              label={"Language"}
                              num={"5.5k"} 
                              dropDownItems={languages} 
                              callback={(selectedOption)=>{
                                handleFilter(selectedOption.toLowerCase(), 'language' )
                              }}
                              />
                    </Grid>
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                      <SmAct 
                      label={"Level"} 
                      num={"4.5k"} 
                      dropDownItems={
                        levelLabel
                      } 
                      callback={(selectedOption)=>{
                        handleFilter( levelLabel.findIndex((element)=> element === selectedOption) + 1 , 'level')
                      }}
                      />
                    </Grid>
                    </Grid>
                </Grid>
                <Grid item  xl={3} lg={3} md={3} sm={12} xs={12} key="search-option">
                <div className="search-group rounded">
                  <input  type="search" className="form-control rounded search-input" placeholder="Search" aria-label="Search" aria-describedby="search-addon" onChange={(event)=>{
                    handleSearch(event);
                  }}/>
                    <span className="search-group-text border-0" id="search-addon">
                      <SearchIcon/>
                    </span>
                  </div>
                  </Grid>
                  <Grid>
                  </Grid>
          </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="asssignment-container">
              {
                !loading && (
                  Object.keys(challenges).map((key) => {
                    return getChallengeCard(challenges[key], key);
                  })
                )
              }
            </Grid>
            <Grid key="pagination container">
              <Pagination 
              currentPage={pageable.pageNumber ? pageable.pageNumber : 1} 
              totalPage={totalPages} 
              handlePage={(pageNumber)=>{
                handleFilter(pageNumber, 'page_number');
              } }/>
            </Grid>
         </div>
      ) : (
          <Loader />
      )}
    </Layout>
      );
}
export { Dashboard};
