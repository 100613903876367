import {
  AUTH_ERROR,
  CHECK_SESSION_STATUS,
  GET_ALL_SESSION,
  GET_SESSION_ID,
  GET_SESSION_STATUS,
  GET_SESSION_VULNERABILITIES,
  LOGOUT,
  SET_SESSION_STATUS,
  START_SESSION,
} from "../actions/types";

const initialState = {
  session: [],
  vulnerabilities: [],
  status: "",
  newSession: {},
  sessionId: [],
  sessionStatus: "",
};

const sessionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_SESSION:
      return {
        ...state,
        session: payload,
      };
    case GET_SESSION_ID:
      return {
        ...state,
        sessionId: payload,
      };
    case SET_SESSION_STATUS:
      return {
        ...state,
        status: payload,
      };
    case GET_SESSION_STATUS:
      return {
        ...state,
        status: state.status,
      };
    case START_SESSION:
      return {
        ...state,
        newSession: payload,
      };
    case CHECK_SESSION_STATUS:
      return {
        ...state,
        sessionStatus: payload,
      };
    case GET_SESSION_VULNERABILITIES:
      return {
        ...state,
        vulnerabilities: payload,
      };
    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        ...payload,
      };

    default:
      return state;
  }
};

export default sessionReducer;
