import React from "react";
import { BkMrk, codeIcon, issuesIcon, securityIcon } from "../../../pages/issue/icons";
import { SmAct } from "../../../pages/issue/utils";
import { pullRequestIcon } from "../../issue/icons";

function SubHeader({ title }) {
  return (
    <div className="topWrps">
      <div className="top-headings league-font">
        <div>
          <img src={BkMrk} alt="" />
          <h4 className="league-font">
            E-CORP / <a href="#">{title}</a>
          </h4>
          <span>Public</span>
        </div>
        <div>
          <SmAct label={"Watch"} num={"858"} />
          <SmAct label={"Fork"} num={"5.5k"} />
          <SmAct label={"Star"} num={"4.5k"} />
        </div>
      </div>
      <div className="tabMenus">
        <a href="#">
          <span>{codeIcon} Code</span>
        </a>
        <a href="#" className="active">
          <span>{issuesIcon} Issues</span>
        </a>
        <a href="#">
          <span>{pullRequestIcon} Pull Requests</span>
        </a>
        <a href="#">
          <span>{securityIcon} Security</span>
        </a>
      </div>

      <div className="border-top cyber-cops-border-color" />
    </div>
  );
}

export { SubHeader };
