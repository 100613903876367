import * as React from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import "./styles/style.css"
const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});


export default function Pagination({
  currentPage = 1,
  totalPage = 1,
  handlePage,
}) {
  const { items } = usePagination({
    count: totalPage,
  });

  return (
    <nav style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
      <List style={{gap: '5%'}}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <button
                type="button"
                className={currentPage == page ? 'active-page' : 'inactive-page'}
                style={{
                  fontWeight: selected ? 'bold' : undefined,
                }}
                {...item}
                onClick={()=>{
                  handlePage(page)
                }}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button type="button" {...item} style={{background: 'none', border: 'none'}} 
              className={
                (type === 'previous' && currentPage === 1) ||
                (type === 'next' && currentPage === totalPage)? "disabled" : '' }
                onClick={()=>{
                  handlePage(type === 'previous' ? currentPage -1 : currentPage + 1)
                }}
                >
                {type === 'previous'  ? 'Priview' : type === 'next' ? 'Next' : type}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
}
