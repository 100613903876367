import React from "react";
import { Typography, Grid } from "@mui/material";
import { Layout } from "../../components";

function Homepage() {
  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" mt={17} align="center">
            Welcome to CyberCops
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
}

export { Homepage };
