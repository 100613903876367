import React from "react";
import { Footer } from "../../footer";
import { Header } from "../../header";

function Layout({ children }) {
  return (
    <main>
      <Header />
      {children}
      <Footer />
    </main>
  );
}

export { Layout };
