import { GET_ATTEMPTS_HISTORY, SET_FIX_STATUS_EXPLOIT } from "../actions/types";

const initialState = {
  exploit: {},
  attemptsHistory: [],
};

const fixReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FIX_STATUS_EXPLOIT:
      return {
        ...state,
        exploit: payload,
      };
    case GET_ATTEMPTS_HISTORY:
      return {
        ...state,
        attemptsHistory: payload,
      };
    default:
      return state;
  }
};

export default fixReducer;
