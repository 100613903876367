import { combineReducers } from "redux";
import session from "./session";
import challenges from "./challenges";
import fix from "./fix";

export default combineReducers({
  session,
  challenges,
  fix,
});
