import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import { AnonymousRoute, AuthenticatedRoute } from "./components";
import {
  Homepage,
  Dashboard,
  ChallengeDetails,
  IssuesDescription,
  Errorpage,
  IssuePage,
} from "./pages";
import store from "./redux/store";
import "./styles/global.css";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AnonymousRoute>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="*" element={<Homepage />} />
          </Routes>
        </AnonymousRoute>
        <AuthenticatedRoute>
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/challengeDetails/:key" element={<ChallengeDetails />} />
            <Route exact path="/vuln/:title" element={<IssuePage />} />
            <Route exact path="/issuesDescription/:key/:title" element={<IssuesDescription />} />
            <Route path="*" element={<Errorpage />} />
          </Routes>
        </AuthenticatedRoute>
      </Router>
    </Provider>
  );
}

export default App;
