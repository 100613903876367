export const GET_ALL_SESSION = "GET_ALL_SESSION";
export const GET_SESSION_ID = "GET_SESSION_ID";
export const GET_SESSION_VULNERABILITIES = "GET_SESSION_VULNERABILITIES";
export const SET_SESSION_STATUS = "SET_SESSION_STATUS";
export const GET_SESSION_STATUS = "GET_SESSION_STATUS";

export const GET_ALL_CHALLENGES = "GET_ALL_CHALLENGES";
export const GET_CHALLENGES_BY_KEY = "GET_CHALLENGES_BY_KEY";
export const GET_VULNERABILITIES_CODE = "GET_VULNERABILITIES_CODE";
export const GET_CHALLENGES_VULNERABILITIES = "GET_CHALLENGES_VULNERABILITIES";
export const GET_CHALLENGES_FIXES = "GET_CHALLENGES_FIXES";
export const GET_FIX_BY_ID_CONTENT = "GET_FIX_BY_ID_CONTENT";
export const GET_ALL_LANGUAGE = "GET_ALL_LANGUAGE";
export const SET_FIX_STATUS_EXPLOIT = "SET_FIX_STATUS_EXPLOIT";
export const GET_ATTEMPTS_HISTORY = "GET_ATTEMPTS_HISTORY";
export const GET_MY_CHALLANGES  = "GET_MY_CHALLANGES";

export const SET_FILTER_LIST = "SET_FILTER_LIST";
export const GET_FILTER_LIST = "GET_FILTER_LIST";

export const SET_ADDITIONAL_CHALLENGES = "SET_ADDITIONAL_CHALLENGES";
export const SET_EMPTY_ADDITIONAL_CHALLENGES = "SET_EMPTY_ADDITIONAL_CHALLENGES";

export const START_SESSION = "START_SESSION";
export const CHECK_SESSION_STATUS = "CHECK_SESSION_STATUS";
export const CHECK_RESPONSE = "CHECK_RESPONSE";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
