import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SyntaxHighlighter from "react-syntax-highlighter";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Modal } from "react-responsive-modal";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import Countdown from 'react-countdown';

import {
  checkResponse,
  getChallengesFixes,
  getChallengesVulnerabilities,
  getFixByIdContent,
  getSessionAttemptsHistory,
  getSessionId,
  getSessionVulnerabilities,
  getVulnerabilityCode,
  setFixStatusExploit,
} from "../../../redux/actions";
import { data, severityChecker, socialShareButtonHelperJSX } from "./utils";

import {
  requestInProcessIcon,
  requestSuccessful,
  requestBlocked,
  userIcon,
  zipperIcon,
  userAvatar,
  greenCheckMark,
  redCrossMark,
  PRIcon,
  BotIcon,
} from "../icons";
import store from "../../../redux/store";
import Loader from "../../../components/common/loader";
import { AttemptsCard, Layout, SubHeader } from "../../../components";
import { checkSessionStatus } from "../../../redux/actions/session";

import "./styles/style.css";
import "react-responsive-modal/styles.css";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { Backdrop } from "@material-ui/core";
// import { CircularProgress } from "@mui/material";
import { SemipolarSpinner } from "react-epic-spinners";
import { Typography } from "@mui/material";

function IssuesDescription() {
  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [key] = useState(localStorage.getItem("key"));
  const { title } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fixesActive, setFixesActive] = useState(1);
  const [exploit, setExploit] = useState("");
  const [vulnerabilitiesState, setVulnerabilitiesState] = useState([]);
  const { sessionId } = useSelector((state) => state.session);
  const { challengesFixes, vulnerabilities } = useSelector((state) => state.challenges);
  const fixCode = useSelector((state) => state.challenges?.fixByIdContent?.code);
  const {
    vulnerabilityCode: { code },
  } = useSelector((state) => state.challenges);
  const { attemptsHistory } = useSelector((state) => state.fix);
  const [open, setOpen] = React.useState(false);
  const [spinner, setSpineer] = React.useState(false);
  // social share url
  const shareUrl = "https://www.hackthebox.com/achievement/machine/616242/482";

  useEffect(() => {
    if (!localStorage.getItem("key")) {
      navigate("/", true);
    }
  }, []);

  useEffect(() => {
    const handleEvent = (event) => {
    console.log("New recieved message from the plaground app")
      // Check the event is from our Vuln App
      if (event.origin !== "https://play.cybercops.fr") {
        console.log("problem with the origin")
        return;
      }
      // Event data is available in event.data
      console.log(event.data);
      setMessage(event.data);
      if (event.data) {
        dispatch(setFixStatusExploit({ key: event.data.key, id: event.data.id })).then(() => {
          const { exploit } = store.getState().fix;
          setExploit(exploit);
        });
        dispatch(getSessionVulnerabilities(event.data.id)).then(() => {
          const data = store.getState().session.vulnerabilities;
          const matchedData = data.filter((item) => item.key === title);
          setVulnerabilitiesState(matchedData);
          dispatch(getSessionAttemptsHistory({ id: event.data.id, title }));
        });
      }
    };

    window.addEventListener("message", handleEvent);

    return () => {
      window.removeEventListener("message", handleEvent);
    };
  }, [message]);

  useEffect(() => {
    try {
      dispatch(getChallengesVulnerabilities(key));
      dispatch(getVulnerabilityCode({ title, key }));
      dispatch(getChallengesFixes({ title, key }));
      dispatch(getFixByIdContent({ title, key, fixId: fixesActive }));
      dispatch(getSessionId(key)).then(() => {
        const { sessionId } = store.getState().session;
        const id = sessionId[0].session.id;
        dispatch(getSessionVulnerabilities(id)).then(() => {
          const data = store.getState().session.vulnerabilities;
          const matchedData = data.filter((item) => item.key === title);
          setVulnerabilitiesState(matchedData);
          dispatch(getSessionAttemptsHistory({ id, title }));
        });
      });
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  const handleStartBtn = (item) => {
    setSpineer(true);
    setTimeout(() => {
      setSpineer(false);
      window.open(
        `${item.demo_link}&key=${title}&id=${sessionId[0].session.id}`,
        "_self"
      );  
    }, 10000);
    
  };

  const handleFixesBtn = (fixId) => {
    setFixesActive(fixId);
    dispatch(getFixByIdContent({ title, key, fixId }));
    dispatch(getSessionAttemptsHistory({ id: sessionId[0].session.id, title }));
  };

  const handleConfirmBtn = () => {
    setLoading(true);
    const id = sessionId[0].session.id;
    dispatch(checkResponse({ title, key, id, fixId: fixesActive }))
      .then(() => {
        setLoading(false);
        dispatch(getSessionAttemptsHistory({ id, title }));
        dispatch(getSessionVulnerabilities(id)).then(() => {
          const data = store.getState().session.vulnerabilities;
          const matchedData = data.filter((item) => item.key === title);
          setVulnerabilitiesState(matchedData);
          dispatch(checkSessionStatus(id)).then(() => {
            const { sessionStatus } = store.getState().session;
            if (sessionStatus.toLowerCase() === "finished") {
              setOpen(true);
            }
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      {vulnerabilities ? (
        vulnerabilities.map((item, i) => {
          return (
            item.key === title && (
              <div className="main-container">
                <div className="issues-description-container container" key={i}>
                  <div className="issues-description-sub-header">
                    <SubHeader title={key} />
                  </div>
                  <div className="issues-description d-flex align-items-center">
                    <h1 className="issues-desc-heading league-font mb-0">{item.title}</h1>
                    <div className="issues-severity-text league-font mx-5">
                      <span
                        className={`severity-indicator severity-indicator-${severityChecker(
                          item.severity
                        )} p-3 consolas-font`}
                      >
                        {item.severity?.slice(0, 1)}
                      </span>
                      <span
                        className={`severity-text severity-text-${severityChecker(
                          item.severity
                        )} p-3 text-uppercase consolas-font`}
                      >
                        {item.severity} Severity
                      </span>
                    </div>
                  </div>
                  <div className="issues-desc-label d-flex align-items-center my-4">
                    <div className="pe-4 text-white border-end">vulnerabilities</div>
                    <div className="px-4 metadata-text border-end">{item.metadata?.cwe}</div>
                    <div className="px-4 text-white">
                      Score: <span className="score-text">910</span>
                    </div>
                  </div>
                  {/* card part */}
                  <div className="card-container d-flex">
                    <div className="me-3 mt-3">
                      <img src={userIcon} className="user-icon" alt="user-icon" />
                    </div>
                    <div className="col-9">
                      <div className="card cyber-cops-card">
                        <div className="card-heading p-3">@Djohnavid021, 5 hours ago</div>
                        <div className="card-body">
                          <div className="issues-desc-container">
                            <span className="issues-desc-text">{item.description}</span>
                          </div>

                          <div className="card-flow-container p-2">
                            <div className="card-flow py-3 px-2 row d-flex justify-content-between">
                              {/* <div className="flow col-4">
                                <span className="ps-4 code-flow-text">Code Flow</span>
                                <span className="text-white ps-4 d-flex">
                                  1 <span className="script-text ms-3">script/bench/server.js</span>
                                </span>
                                <span className="text-white ps-4 d-flex">
                                  2 <span className="script-text ms-3">script/bench/server.js</span>
                                </span>
                                <span className="text-white ps-4 d-flex">
                                  3 <span className="script-text ms-3">script/bench/server.js</span>
                                </span>
                                <span className="text-white ps-4 d-flex bg-red">
                                  4{" "}
                                  <span className="script-text text-white ms-3">
                                    script/bench/server.js
                                  </span>
                                </span>
                              </div> */}
                              <div className="code-card-container col-12">
                                <div className="card-heading px-4 py-2">server.js</div>
                                <div className="card code-card">
                                  <div className="issues-desc-container">
                                    <span className="issues-desc-text">
                                      {
                                        <SyntaxHighlighter
                                          language={`${item.language[0]}`}
                                          style={github}
                                          showLineNumbers
                                          wrapLines
                                          wrapLongLines
                                        >
                                          {code}
                                        </SyntaxHighlighter>
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="reference-container mt-5">
                                <div className="reference">
                                  <span className="reference-text">Reference</span>
                                  {item?.metadata?.references?.map((item, i) => {
                                    return (
                                      <a
                                        href={item}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="reference-item text-decoration-underline d-block"
                                        key={i}
                                      >
                                        {item}
                                      </a>
                                    );
                                  })}
                                </div>
                              </div>

                              <div className="start-btn-container d-flex justify-content-between align-items-center mt-3">
                                <div>
                                  <span className="text-white">
                                    Please enter the virtual playground to reproduce this issue
                                  </span>
                                </div>
                                <div
                                  className="btn-container d-flex justify-content-between align-items-center"
                                  style={
                                    vulnerabilitiesState?.[0]?.status === "unsolved" &&
                                      attemptsHistory.length <= 0
                                      ? {}
                                      : { opacity: "0.5", pointerEvents: "none" }
                                  }
                                  role="button"
                                  onClick={
                                    () => {
                                      vulnerabilitiesState?.[0]?.status === "unsolved" &&
                                        attemptsHistory.length <= 0
                                        ? handleStartBtn(item)
                                        : () => {
                                        }

                                    }}
                                >
                                  <div className="zipper-icon-container">{zipperIcon}</div>
                                  <div className="start-btn btn text-uppercase league-font text-white px-4">
                                    Start
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-2 ms-4">
                      <div className="assignee-container">
                        <div className="assignee-text mb-4">
                          <h6 className="league-font mb-4">ASSIGNEE</h6>
                          <span className="assignee-name p-3">
                            <img src={userIcon} alt="user-icon" />{" "}
                            <span className="assignee-name-text ms-2 text-white">
                              @Djohnavid021
                            </span>
                          </span>
                        </div>
                        <div className="label-container mb-3">
                          <h6 className="league-font">LABEL</h6>
                          <span className="d-inline-flex label px-4 py-2">Bug</span>
                        </div>
                        <div className="milestone-container mb-4">
                          <h6 className="league-font">MILESTONE</h6>
                          <span className="milestone-text">Not defined yet.</span>
                        </div>
                        <div className="project-container">
                          <h6 className="league-font">PROJECT</h6>
                          <span className="project-text">None.</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {Object.keys(message).length ||
                    exploit ||
                    vulnerabilitiesState?.[0]?.status === "exploited" ||
                    (vulnerabilitiesState?.[0]?.status === "unsolved" &&
                      attemptsHistory.length > 0) ||
                    (vulnerabilitiesState?.[0]?.status === "solved" && attemptsHistory.length > 0) ? (
                    <div className="fix-container">
                      <div className="card-container my-4 d-flex">
                        <div className="me-3 mt-3">
                          <img src={userIcon} className="user-icon" alt="user-icon" />
                        </div>
                        <div className="col-9">
                          <div className="card cyber-cops-card">
                            <div className="card-heading p-3">@Djohnavid021, 5 hours ago</div>
                            <div className="card-body">
                              <div className="issues-desc-container">
                                <span className="issues-desc-text">{item.description}</span>
                              </div>

                              <div className="card-flow-container">
                                <div className="card-flow">
                                  <div className="code-card-container">
                                    <div className="card-heading d-flex flex-wrap justify-content-between align-items-center">
                                      <span className="mb-0 px-4 py-4">server.js</span>
                                      <div className="d-flex align-items-center px-4 py-4">
                                        <span className="mb-0 me-3">Choose correct fix: </span>
                                        <div
                                          className="btn-group cyber-cops-btn-group"
                                          role="group"
                                          aria-label="Basic example"
                                        >
                                          {challengesFixes.map((_item, i) => {
                                            return (
                                              <button
                                                type="button"
                                                className={`btn challenges-fixes-btn ${fixesActive === i + 1 ? "fixes-btn-active" : null
                                                  }`}
                                                style={
                                                  vulnerabilitiesState?.[0]?.status === "solved"
                                                    ? {
                                                      opacity: "0.7",
                                                      pointerEvents: "none",
                                                    }
                                                    : {}
                                                }
                                                key={i}
                                                onClick={
                                                  vulnerabilitiesState?.[0]?.status !== "solved"
                                                    ? () => handleFixesBtn(i + 1)
                                                    : () => { }
                                                }
                                              >
                                                {i + 1}
                                              </button>
                                            );
                                          })}
                                        </div>
                                        <div className="confirm-btn-container ms-3">
                                          <button
                                            className="confirm-btn btn px-4 py-2 league-font text-uppercase"
                                            onClick={handleConfirmBtn}
                                            disabled={
                                              vulnerabilitiesState?.[0]?.status === "solved"
                                            }
                                          >
                                            Confirm
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card code-card">
                                      <div className="issues-desc-container">
                                        <div className="issues-desc-text">
                                          {code && fixCode && (
                                            <ReactDiffViewer
                                              oldValue={code}
                                              newValue={fixCode}
                                              splitView={false}
                                              compareMethod={DiffMethod.LINES}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="attempts-history-container">
                        <div className="attempts-history-user-details">
                          {attemptsHistory.length > 0 && (
                            <span className="consolas-font user-details-text mb-0">
                              <span className="text-uppercase league-font">okorach</span> added{" "}
                              {attemptsHistory && attemptsHistory.length} commits 2 hours ago
                            </span>
                          )}
                        </div>
                        {attemptsHistory &&
                          attemptsHistory.map((item, i) => {
                            return (
                              <div className="commit-history" key={i}>
                                <div className="commit-history-inner-container d-flex justify-content-between align-items-center">
                                  <div className="d-flex align-items-center">
                                    <img src={userAvatar} alt="" />
                                    <span className="commit-history-text mb-0 ms-3">
                                      Fix #{i + 1}
                                    </span>
                                  </div>
                                  <span className="commit-checker mb-0 ms-5">
                                    <img
                                      src={item.correct ? greenCheckMark : redCrossMark}
                                      alt=""
                                    />
                                    <span className="commit-checker-text ms-3">{`${107 + i
                                      }d95f`}</span>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      <div className="attempts-history-container">
                        {(vulnerabilitiesState?.[0]?.status === "solved" ||
                          (vulnerabilitiesState?.[0]?.status === "unsolved" &&
                            attemptsHistory.length > 0)) && (
                            <div className="attempts-history-card-container">
                              {attemptsHistory
                                .slice(attemptsHistory.length - 1, attemptsHistory.length)
                                .map((item, i) => (
                                  <div className="attempts-card-container my-4 d-flex" key={i}>
                                    <div className="me-3 mt-3">
                                      <img src={PRIcon} className="user-icon" alt="user-icon" />
                                    </div>
                                    <div className="col-9">
                                      <div
                                        className={`card cyber-cops-card ${attemptsHistory.length > 1 && "attempts-card"
                                          }`}
                                        key={i}
                                      >
                                        {loading ? (
                                          <div className="card-body">
                                            <AttemptsCard
                                              icon={requestInProcessIcon}
                                              title="Request In Process"
                                            />
                                          </div>
                                        ) : (
                                          <div className="card-body">
                                            <AttemptsCard
                                              icon={item.correct ? requestSuccessful : requestBlocked}
                                              title={
                                                item.correct
                                                  ? "Merge Request Successful"
                                                  : "Merge Request Blocked"
                                              }
                                              subtitle={item.explanation}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))
                                .reverse()}
                            </div>
                          )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            )
          );
        })
      ) : (
        <Loader />
      )}
      {/* modal container */}
      <div className="modal-container">
        {open && (
          <Confetti width={width} height={height}>
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              closeOnOverlayClick={false}
              classNames={{
                overlay: "customOverlay",
                modal: "customModal",
              }}
              reserveScrollBarGap
            >
              <div className="modal-body">
                <div className="modal-icon">
                  <img src={BotIcon} alt="bot-icon" />
                </div>
                <div className="congratulation-text my-4">
                  <h3 className="congrats-text text-center">Congratulations</h3>
                  <p className="congrats-subtext text-center">{`All security issues has been fixed !`}</p>
                </div>
                <div className="share-btn-grp d-flex flex-column align-items-center justify-content-center">
                  {data.map((item, i) => (
                    <div
                      className={`${item.name}-share-btn d-flex justify-content-center my-1`}
                      key={i}
                    >
                      {item.name === "twitter" && (
                        <TwitterShareButton url={shareUrl}>
                          {socialShareButtonHelperJSX(item)}
                        </TwitterShareButton>
                      )}
                      {item.name === "facebook" && (
                        <FacebookShareButton url={shareUrl}>
                          {socialShareButtonHelperJSX(item)}
                        </FacebookShareButton>
                      )}
                      {item.name === "linkedin" && (
                        <LinkedinShareButton url={shareUrl}>
                          {socialShareButtonHelperJSX(item)}
                        </LinkedinShareButton>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          </Confetti>
        )}
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinner}
        style={{ backgroundColor: '#101520', zIndex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        <SemipolarSpinner></SemipolarSpinner>
        <Typography style={{color: '#ffff', }}>Hang Tight !</Typography>
        <Countdown date={Date.now() + 10000}  renderer={props => <div>{<Typography style={{color: '#ffff', }}>it may take upto {props.total/1000} seconds</Typography>}</div>}/>
        <Typography style={{color: '#ffff', }}>You&#39;re beign redirected to another page,</Typography>
      </Backdrop>
    </Layout>
  );
}

export { IssuesDescription };
