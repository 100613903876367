import {
  java,
  python,
  react,
  angular,
  javascript,
  go,
  html,
  ruby,
  typescript,
  cPlusPlus,
  cSharp,
  php,
  spring,
  nestjs,
  scala,
  flask,
  express,
  symfony,
} from "./icons";

const iconPicker = (lang) => {
  const language = lang.toLowerCase();
  if (language === "java-app") {
    return java;
  } else if (language === "python-app") {
    return python;
  } else if (language === "react-app") {
    return react;
  } else if (language === "angular-app") {
    return angular;
  } else if (language === "scala-app") {
    return scala;
  } else if (language === "php-app") {
    return php;
  } else if (language === "html") {
    return html;
  } else if (language === "go-app") {
    return go;
  } else if (language === "ruby-app") {
    return ruby;
  } else if (language === "typescript-app") {
    return typescript;
  } else if (language === "javascipt-app") {
    return javascript;
  } else if (language === "cpp-app") {
    return cPlusPlus;
  } else if (language === "csharp-app") {
    return cSharp;
  } else if (language === "spring-app") {
    return spring;
  } else if (language === "nestjs-app") {
    return nestjs;
  } else if (language === "flask-app") {
    return flask;
  } else if (language === "express-app") {
    return express;
  } else if (language === "symfony-app") {
    return symfony;
  }
};

export { iconPicker };
