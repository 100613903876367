import React from 'react'
import { Card } from '@material-ui/core'
import "./style.css";
import { useNavigate } from 'react-router';
import { useDispatch } from "react-redux";
import { setSessionStatus } from '../../../redux/actions';
import { useState } from 'react';

export function ChallengeCard (props){
      const navigate = useNavigate();
      const dispatch = useDispatch();
    const {devicon,logo, status, title, numberOfExercise, level, LevelAction} = props;
    const [iconType , setIconType] = useState('plain');
    const levelLabel = {
        1: "BIGINNER",
        2: "MEDIUM",
        3: "ADVANCED",
    }
    const colorClass = {
        'COMPLETD': 'completed',
        'NOT STARTED': 'not-started',
        'NEW': 'new',
        'NOT COMPLETED': 'not-completed',
        'STARTED': 'started'
    }
    const handleBtn = (key) => {
        console.log('handleBtn', key);
        setSessionStatus(status)
        dispatch(setSessionStatus());  
        navigate(`/challengeDetails/${key}`);
      };
    return(
        <Card className="my-2 ChallengeCard">
            <div   className="challenge-header" >
                    <div  className="Main-logo">
                    <div  className="challenge-logo" >
                    <img className="logo" onError={()=>{setIconType('original')}} src={`https://cdn.jsdelivr.net/gh/devicons/devicon/icons/${devicon}/${devicon}-${iconType}.svg`} />
                    </div>
                    <div  className={'statusLabel ' +  colorClass[status]}><div className="challenge-status" >{status}</div></div>
                    </div>
            </div>
            <div  className="challenge-content" >
                <div className="challenge-name">{title}</div>
                <div className="number-of-exercise-content">
                    <div className="number-of-expercise-label">Number Of Exercise</div>
                    <div className="number-of-expercise-value">{numberOfExercise}</div>
                </div>
                <div className="level-container">
                <div className="level-icon">
                    <span className={"round-level "+ (level >= 1 ? "brown" : 'grey')}></span>
                    <span className={"round-level "+ (level >= 2 ? "brown" : 'grey')}></span>
                    <span className={"round-level "+ (level >= 3 ? "brown" : "grey")}></span>
                </div>
                <div className="level-name">{levelLabel[level]}</div>
                </div>
            </div>
            <div  className="challenge-footer" >
                <button className="level-action" onClick={()=>{
                    handleBtn(logo)
                }}>
                    <div className="level-label">{LevelAction}</div>
                </button>
            </div>
        </Card>
    )
}