/* eslint-disable react/react-in-jsx-scope */
import Eye from "../../../assets/imgs/eye.svg";
import ArrowDown from "../../../assets/imgs/down-arrow.svg";
import Link from "../../../assets/imgs/link.svg";
import Star from "../../../assets/imgs/star.svg";
import BkMrk from "../../../assets/imgs/bookmark.svg";
import QrIcon from "../../../assets/imgs/qr.svg";
import IssueIcon from "../../../assets/imgs/idea.svg";
import ReqIcon from "../../../assets/imgs/request.svg";
import KnifIcon from "../../../assets/imgs/knife.svg";
import FilterIcon from "../../../assets/imgs/filter.svg";
import LabelIcon from "../../../assets/imgs/label.svg";
import Tick from "../../../assets/imgs/tick.svg";
import ProTip from "../../../assets/imgs/tips.svg";
import userIcon from "../../../assets/imgs/issues-desc/user-icon.svg";
import PRIcon from "../../../assets/imgs/issues-desc/pr-icon.svg";
import requestInProcessIcon from "../../../assets/imgs/issues-desc/request-in-process.svg";
import requestSuccessful from "../../../assets/imgs/issues-desc/merged-icon.svg";
import requestBlocked from "../../../assets/imgs/issues-desc/merged-request-blocked.svg";
import userAvatar from "../../../assets/imgs/issues-desc/user-avatar.svg";
import greenCheckMark from "../../../assets/imgs/issues-desc/green-check-mark.svg";
import redCrossMark from "../../../assets/imgs/issues-desc/red-cross-mark.svg";
import BotIcon from '../../../assets/imgs/modal/bot-icon.png'

const codeIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M5.885 6.9725L2.57625 10.28L5.885 13.5875L5 14.4725L1.25 10.7225V9.8375L5 6.0875L5.885 6.9725ZM14.635 6.0875L13.75 6.9725L17.0588 10.28L13.75 13.5875L14.635 14.4725L18.385 10.7225V9.8375L14.635 6.0875ZM6.135 16.25L7.2525 16.81L13.5025 4.31L12.385 3.75L6.135 16.25V16.25Z"
        fill="white"
      />
    </g>
  </svg>
);

const issuesIcon = (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.375 0.25C6.22012 0.25 4.15349 1.10602 2.62976 2.62976C1.10602 4.15349 0.25 6.22012 0.25 8.375C0.25 10.5299 1.10602 12.5965 2.62976 14.1202C4.15349 15.644 6.22012 16.5 8.375 16.5C10.5299 16.5 12.5965 15.644 14.1202 14.1202C15.644 12.5965 16.5 10.5299 16.5 8.375C16.5 6.22012 15.644 4.15349 14.1202 2.62976C12.5965 1.10602 10.5299 0.25 8.375 0.25ZM8.375 15.25C6.55164 15.25 4.80295 14.5257 3.51364 13.2364C2.22433 11.947 1.5 10.1984 1.5 8.375C1.5 6.55164 2.22433 4.80295 3.51364 3.51364C4.80295 2.22433 6.55164 1.5 8.375 1.5C10.1984 1.5 11.947 2.22433 13.2364 3.51364C14.5257 4.80295 15.25 6.55164 15.25 8.375C15.25 10.1984 14.5257 11.947 13.2364 13.2364C11.947 14.5257 10.1984 15.25 8.375 15.25Z"
      fill="white"
    />
    <path
      d="M8.375 9.625C9.06536 9.625 9.625 9.06536 9.625 8.375C9.625 7.68464 9.06536 7.125 8.375 7.125C7.68464 7.125 7.125 7.68464 7.125 8.375C7.125 9.06536 7.68464 9.625 8.375 9.625Z"
      fill="white"
    />
  </svg>
);

const pullRequestIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M15.417 18.3333C15.859 18.3333 16.2829 18.1577 16.5955 17.8452C16.9081 17.5326 17.0837 17.1087 17.0837 16.6667C17.0837 16.2246 16.9081 15.8007 16.5955 15.4881C16.2829 15.1756 15.859 15 15.417 15C14.975 15 14.551 15.1756 14.2385 15.4881C13.9259 15.8007 13.7503 16.2246 13.7503 16.6667C13.7503 17.1087 13.9259 17.5326 14.2385 17.8452C14.551 18.1577 14.975 18.3333 15.417 18.3333V18.3333ZM4.58366 4.99999C5.02569 4.99999 5.44961 4.8244 5.76217 4.51183C6.07473 4.19927 6.25033 3.77535 6.25033 3.33332C6.25033 2.8913 6.07473 2.46737 5.76217 2.15481C5.44961 1.84225 5.02569 1.66666 4.58366 1.66666C4.14163 1.66666 3.71771 1.84225 3.40515 2.15481C3.09259 2.46737 2.91699 2.8913 2.91699 3.33332C2.91699 3.77535 3.09259 4.19927 3.40515 4.51183C3.71771 4.8244 4.14163 4.99999 4.58366 4.99999V4.99999ZM4.58366 18.3333C5.02569 18.3333 5.44961 18.1577 5.76217 17.8452C6.07473 17.5326 6.25033 17.1087 6.25033 16.6667C6.25033 16.2246 6.07473 15.8007 5.76217 15.4881C5.44961 15.1756 5.02569 15 4.58366 15C4.14163 15 3.71771 15.1756 3.40515 15.4881C3.09259 15.8007 2.91699 16.2246 2.91699 16.6667C2.91699 17.1087 3.09259 17.5326 3.40515 17.8452C3.71771 18.1577 4.14163 18.3333 4.58366 18.3333V18.3333Z"
        stroke="white"
        strokeLinejoin="round"
      />
      <path
        d="M4.58398 4.99999V15M10.0007 4.16666H13.7507C14.1927 4.16666 14.6166 4.34225 14.9292 4.65481C15.2417 4.96737 15.4173 5.3913 15.4173 5.83332V15"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 6.66666L10 4.16666L12.5 1.66666"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const securityIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M10 14.8306C9.00579 14.8295 8.05264 14.434 7.34963 13.731C6.64662 13.028 6.25116 12.0748 6.25 11.0806V7.04501L10 5.17001L13.75 7.04501V11.08C13.7488 12.0742 13.3534 13.0274 12.6504 13.7304C11.9474 14.4334 10.9942 14.8289 10 14.83V14.8306ZM7.5 7.81689V11.0806C7.5 11.7437 7.76339 12.3796 8.23223 12.8484C8.70107 13.3172 9.33696 13.5806 10 13.5806C10.663 13.5806 11.2989 13.3172 11.7678 12.8484C12.2366 12.3796 12.5 11.7437 12.5 11.0806V7.81689L10 6.56689L7.5 7.81689Z"
        fill="white"
      />
      <path
        d="M10 19.375C9.88932 19.3751 9.78061 19.3458 9.685 19.29L2.185 14.915C2.09064 14.8599 2.01236 14.7811 1.95797 14.6864C1.90357 14.5916 1.87497 14.4843 1.875 14.375V5.625C1.87497 5.51575 1.90357 5.4084 1.95797 5.31365C2.01236 5.2189 2.09064 5.14007 2.185 5.085L9.685 0.710002C9.7806 0.654214 9.88931 0.624817 10 0.624817C10.1107 0.624817 10.2194 0.654214 10.315 0.710002L17.815 5.085L17.185 6.165L10 1.97375L3.125 5.98375V14.0163L10 18.0269L16.875 14.0163V9.375H18.125V14.375C18.125 14.4843 18.0964 14.5916 18.042 14.6864C17.9876 14.7811 17.9094 14.8599 17.815 14.915L10.315 19.29C10.2194 19.3458 10.1107 19.3751 10 19.375Z"
        fill="white"
      />
    </g>
  </svg>
);

const zipperIcon = (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none">
    <path
      d="M25.1043 3.58636L35.0112 9.73747L45.2917 15.2415L44.9181 26.8967L45.2917 38.5519L35.0112 44.056L25.1043 50.2071L15.1974 44.056L4.91694 38.5519L5.2905 26.8967L4.91694 15.2415L15.1974 9.73747L25.1043 3.58636Z"
      fill="url(#paint0_linear_149_2547)"
    />
    <rect
      x="7.17285"
      y="26.8967"
      width="35.8621"
      height="8.06897"
      fill="url(#paint1_linear_149_2547)"
      fillOpacity="0.5"
    />
    <path
      d="M33.6925 11.8614L33.7606 11.9036L33.8312 11.9415L42.7432 16.7129L42.4194 26.8166L42.4168 26.8967L42.4194 26.9768L42.7432 37.0806L33.8312 41.852L33.7606 41.8898L33.6925 41.9321L25.1043 47.2644L16.5161 41.9321L16.448 41.8898L16.3774 41.852L7.46538 37.0806L7.78922 26.9768L7.79178 26.8967L7.78922 26.8166L7.46538 16.7129L16.3774 11.9415L16.448 11.9036L16.5161 11.8614L25.1043 6.52905L33.6925 11.8614Z"
      stroke="#E1E6EB"
      strokeWidth="5"
    />
    <path
      d="M25.2988 51.6266V43.6636C25.2988 43.4752 25.5355 43.3913 25.6541 43.5376L29.9835 48.8732L25.6099 51.7929C25.477 51.8817 25.2988 51.7864 25.2988 51.6266Z"
      fill="#C5CDDD"
    />
    <path
      d="M25.2988 51.6266V43.6636C25.2988 43.4752 25.0622 43.3913 24.9435 43.5376L20.6141 48.8732L24.9878 51.7929C25.1207 51.8817 25.2988 51.7864 25.2988 51.6266Z"
      fill="#E5EAF4"
    />
    <path
      d="M47.756 41.6666L42.1253 36.0359C41.9921 35.9027 42.1001 35.676 42.2874 35.6955L49.1217 36.4071L48.0936 41.5643C48.0623 41.721 47.869 41.7796 47.756 41.6666Z"
      fill="#C5CEDD"
    />
    <path
      d="M47.756 41.6666L42.1253 36.0359C41.9921 35.9027 41.7655 36.0107 41.785 36.198L42.4965 43.0322L47.6537 42.0042C47.8104 41.9729 47.869 41.7796 47.756 41.6666Z"
      fill="#E4E9F3"
    />
    <path
      d="M3.577 12.4777L9.2077 18.1084C9.3409 18.2416 9.23292 18.4683 9.04557 18.4487L2.21135 17.7372L3.23944 12.58C3.27068 12.4233 3.46401 12.3647 3.577 12.4777Z"
      fill="#C5CEDD"
    />
    <path
      d="M3.577 12.4777L9.2077 18.1084C9.3409 18.2416 9.56756 18.1336 9.54805 17.9463L8.8365 11.112L3.67932 12.1401C3.52261 12.1714 3.46401 12.3647 3.577 12.4777Z"
      fill="#E4E9F3"
    />
    <path
      d="M3.577 41.6665L9.2077 36.0358C9.3409 35.9026 9.56756 36.0105 9.54805 36.1979L8.8365 43.0321L3.67932 42.004C3.52261 41.9728 3.46401 41.7795 3.577 41.6665Z"
      fill="#C5CEDD"
    />
    <path
      d="M3.577 41.6665L9.2077 36.0358C9.3409 35.9026 9.23292 35.6759 9.04557 35.6954L2.21135 36.407L3.23944 41.5641C3.27068 41.7209 3.46401 41.7795 3.577 41.6665Z"
      fill="#E4E9F3"
    />
    <path
      d="M47.757 12.4778L42.1263 18.1085C41.9931 18.2417 41.7664 18.1337 41.7859 17.9464L42.4975 11.1122L47.6547 12.1403C47.8114 12.1715 47.87 12.3648 47.757 12.4778Z"
      fill="#C5CEDD"
    />
    <path
      d="M47.757 12.4778L42.1263 18.1085C41.9931 18.2417 42.1011 18.4684 42.2884 18.4489L49.1226 17.7373L48.0945 12.5801C48.0633 12.4234 47.87 12.3648 47.757 12.4778Z"
      fill="#E4E9F3"
    />
    <path
      d="M25.2979 0.373833L25.2979 8.33685C25.2979 8.52521 25.0612 8.60914 24.9425 8.46287L20.6132 3.12719L24.9868 0.207492C25.1197 0.118771 25.2979 0.214037 25.2979 0.373833Z"
      fill="#C5CDDD"
    />
    <path
      d="M25.2979 0.373833L25.2979 8.33685C25.2979 8.52521 25.5345 8.60914 25.6532 8.46287L29.9825 3.1272L25.6089 0.207492C25.476 0.118771 25.2979 0.214037 25.2979 0.373833Z"
      fill="#E5EAF4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.4323 32C29.3873 26.893 27.0003 25.547 27.0003 23V20H27.5003C27.6329 20 27.7601 19.9473 27.8538 19.8536C27.9476 19.7598 28.0003 19.6326 28.0003 19.5V18.5C28.0003 18.3674 27.9476 18.2402 27.8538 18.1464C27.7601 18.0527 27.6329 18 27.5003 18H22.5003C22.3677 18 22.2405 18.0527 22.1467 18.1464C22.053 18.2402 22.0003 18.3674 22.0003 18.5V19.5C22.0003 19.6326 22.053 19.7598 22.1467 19.8536C22.2405 19.9473 22.3677 20 22.5003 20H23.0003V23C23.0003 25.547 20.6133 26.893 18.5683 32C17.9173 33.625 16.2453 36 25.0003 36C33.7553 36 32.0833 33.625 31.4323 32V32ZM29.8153 33.751C29.1133 33.961 27.7163 34.2 25.0003 34.2C22.2843 34.2 20.8873 33.961 20.1853 33.751C19.9363 33.677 19.8393 33.388 19.9273 33.123C20.1473 32.453 20.5623 31.295 21.3383 30.002C23.2343 26.843 25.2013 30.499 26.8383 30.499C28.4753 30.499 28.0263 28.938 28.6623 30.002C29.2475 30.9864 29.7208 32.0333 30.0733 33.123C30.1613 33.388 30.0643 33.676 29.8153 33.751V33.751Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.4323 32C29.3873 26.893 27.0003 25.547 27.0003 23V20H27.5003C27.6329 20 27.7601 19.9473 27.8538 19.8536C27.9476 19.7598 28.0003 19.6326 28.0003 19.5V18.5C28.0003 18.3674 27.9476 18.2402 27.8538 18.1464C27.7601 18.0527 27.6329 18 27.5003 18H22.5003C22.3677 18 22.2405 18.0527 22.1467 18.1464C22.053 18.2402 22.0003 18.3674 22.0003 18.5V19.5C22.0003 19.6326 22.053 19.7598 22.1467 19.8536C22.2405 19.9473 22.3677 20 22.5003 20H23.0003V23C23.0003 25.547 20.6133 26.893 18.5683 32C17.9173 33.625 16.2453 36 25.0003 36C33.7553 36 32.0833 33.625 31.4323 32V32ZM29.8153 33.751C29.1133 33.961 27.7163 34.2 25.0003 34.2C22.2843 34.2 20.8873 33.961 20.1853 33.751C19.9363 33.677 19.8393 33.388 19.9273 33.123C20.1473 32.453 20.5623 31.295 21.3383 30.002C23.2343 26.843 25.2013 30.499 26.8383 30.499C28.4753 30.499 28.0263 28.938 28.6623 30.002C29.2475 30.9864 29.7208 32.0333 30.0733 33.123C30.1613 33.388 30.0643 33.676 29.8153 33.751V33.751Z"
      fill="#808080"
      fillOpacity="0.4"
    />
    <defs>
      <linearGradient
        id="paint0_linear_149_2547"
        x1="25.1043"
        y1="3.58636"
        x2="25.1043"
        y2="50.2071"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A9B4C7" />
        <stop offset="0.0001" stopColor="#D4D4D5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_149_2547"
        x1="25.1039"
        y1="26.8967"
        x2="25.1039"
        y2="38.5519"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6C0D2" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export {
  Eye,
  ArrowDown,
  Link,
  Star,
  BkMrk,
  QrIcon,
  IssueIcon,
  ReqIcon,
  KnifIcon,
  FilterIcon,
  LabelIcon,
  Tick,
  ProTip,
  codeIcon,
  issuesIcon,
  pullRequestIcon,
  securityIcon,
  userIcon,
  zipperIcon,
  PRIcon,
  requestInProcessIcon,
  requestSuccessful,
  requestBlocked,
  userAvatar,
  greenCheckMark,
  redCrossMark,
  BotIcon
};
