import React from "react";

function Loader({ height }) {
  return (
    <div
      className="container loader d-flex justify-content-center align-items-center"
      style={{ height: `${height}` }}
    >
      <div className="loader-container">
        <div className="spinner-grow mx-2 text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow mx-2 text-secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow mx-2 text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow mx-2 text-danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow mx-2 text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow mx-2 text-info" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow mx-2 text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow mx-2 text-dark" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
}

Loader.defaultProps = {
  height: "75vh",
};

export default Loader;
