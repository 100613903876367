import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Layout } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  getChallengesByKey,
  getChallengesVulnerabilities,
  getSessionId,
} from "../../../redux/actions";

import { iconPicker } from "../../../utils/iconPicker";
import { frame, smallFrame, userIcon } from "./icons";
import Loader from "../../../components/common/loader";
import "./styles/style.css";
import { getSessionStatus, startSession } from "../../../redux/actions";
import store from "../../../redux/store";

function ChallengeDetails() {
  let { key } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [callIconPicker, setCallIconPicker] = useState(false);
  const challengesByKeyState = useSelector((state) => state.challenges.challengesByKey);
  const challengesVulnerabilities = useSelector((state) => state.challenges.vulnerabilities);
  const { sessionId } = useSelector((state) => state.session);

  useEffect(() => {
    try {
      setLoading(true);
      dispatch(getSessionStatus());
      dispatch(getChallengesByKey(key)).then(() => {
        const { challengesByKey } = store.getState().challenges;
        setCallIconPicker(true);
        setLoading(false);
        dispatch(getSessionId(challengesByKey.key));
      });
      dispatch(getChallengesVulnerabilities(key));
    } catch (error) {
      console.log("error", error);
    }
  }, [dispatch]);

  const handleStartChallenge = async (title) => {
    localStorage.setItem("key", title);
    console.log(sessionId);
    console.log(sessionId[0]);
    if ( sessionId === undefined || sessionId.length == 0 ) {
        dispatch(startSession(title)).then(() => {
        let newSession = store.getState().session.newSession.data.id;
        console.log(newSession);
        navigate(`/vuln/${newSession}`);
      });
    }else if (sessionId[0].session.status === "started" || sessionId[0].session.status === "affected") {
      navigate(`/vuln/${sessionId?.[0].session?.id}`);
    } 
  };

  return (
    <Layout>
      {!loading ? (
        <div className="container">
          <div className="border-top cyber-cops-border-color" />
          <div className="challenge-name-container my-3">
            {callIconPicker && (
              <div className="d-flex align-items-center">
                <div className="icon-container">
                  {iconPicker(challengesByKeyState?.technologies[0])}
                </div>
                <div className="challenge-title ms-4">
                  <h3 className="text-white text-uppercase league-font mb-0">
                    {challengesByKeyState?.title} 
                  </h3>
                </div>
                <div className="difficulties-level-badge-container">
                  <p className="difficulties-level-badge mb-0 ms-5 cyber-cops-badge-bg text-center">
                    Hard
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* two frame */}
          <div className="frame-container">
            <div className="d-flex justify-content-between align-items-center">
              <div className="frame position-relative">
                <div className="frame-svg">{frame}</div>
                <div className="frame-description position-absolute">
                  {challengesByKeyState?.description}
                </div>
              </div>
              <div className="frame position-relative">
                <div className="small-frame-svg">{smallFrame}</div>
                <div className="small-frame-vulnerabilities-description position-absolute text-center">
                  <p className="vulnerabilities-text">{challengesVulnerabilities?.length}</p>
                  <p className="vulnerabilities-text">Vulnerabilities</p>
                </div>
                <div className="small-frame-minute-description position-absolute text-center">
                  <p className="vulnerabilities-text">6000</p>
                  <p className="vulnerabilities-text">Minutes</p>
                </div>
                <div className="learning-container position-absolute">
                  <div className="start-learning-text-container d-flex justify-content-between align-items-center">
                    <div className="start-learning-text">
                      <p className="start-learning-title mb-0">Start Learning</p>
                    </div>
                    <div className="start-challenge-btn-container">
                      <button
                        className="btn start-challenge-btn league-font text-uppercase"
                        onClick={() => handleStartChallenge(key)}
                      >
                        {sessionId.length &&
                        (sessionId[0].session.status === "started" ||
                          sessionId[0].session.status === "affected")
                          ? "Continue"
                          : "Start Challenge"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="completed-by-container position-absolute">
                  <div className="completed-by-text-container d-flex justify-content-between align-items-center">
                    <div className="completed-by-text">
                      <p className="completed-by-title mb-0">Completed by:</p>
                    </div>
                    <div className="completed-user-icon-container">{userIcon}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Layout>
  );
}

export { ChallengeDetails };
