import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import UserService from "./services/UserService";

const root = () =>
  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

UserService.initKeycloak(root);
