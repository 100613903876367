import React from "react";

function AttemptsCard({ icon, title, subtitle }) {
  return (
    <div className="card-container my-4 d-flex">
      <div className="col-12">
        <div className="card-title">
          <div className="d-flex align-items-center">
            <img src={icon} className="user-icon" alt="user-icon" />
            <h3 className="card-title-text text-uppercase league-font mx-3 mb-0">{title}</h3>
          </div>
        </div>
        <div className="card-desc-container">
          <p className="card-desc-text">
            {subtitle
              ? subtitle
              : `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Montes, eu morbi curabitur
            viverra sociis dignissim risus, rhoncus. Elit varius mauris vel, dolor. Sed arcu nec cum
            venenatis mauris. Sit nulla amet ac ullamcorper condimentum vestibulum ac ultricies. In
            ultricies urna ridiculus elit, at non eget mattis posuere. Quis nisl, sollicitudin sed
            at dapibus urna ornare ac. In volutpat malesuada sed ullamcorper facilisis gravida.
            Turpis arcu vitae felis dictumst eget donec iaculis urna.`}
          </p>
        </div>
      </div>
    </div>
  );
}

export { AttemptsCard };
